.label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-label);
    background: var(--background-label);
    border: none;
	border-radius: var(--border-radius-label);
	width: 100%;
	height: var(--height-label);
}

.label:hover:enabled {
    background-color: var(--background-label-hover);
}

.sum {
	color: var(--color-sum);
	background: var(--background-sum);
	border: var(--border-sum);
}

.total-sum {
	font-size: var(--font-size-title);
	color: var(--color-total-sum);
	background: var(--background-total-sum);
	border: var(--border-total-sum);
    grid-column-start: 3;
}

.column-symbol {
	background: var(--background-column-symbol);
	border: var(--border-column-symbol);
}

.column-symbol img {
    filter: var(--filter-column-symbol);
}

.label img {
    filter: var(--filter-label);
}

.tooltip {
    top: 14px;
    left: 8px;
    position: relative;
    visibility: hidden;
    width: max-content;
}

.label:hover + .tooltip {
    visibility: visible;
}

.suggestion {
    animation: blink 0.5s infinite;
}

@keyframes blink {
    0% {
        color: var(--color-red);
    }
    50% {
        color: var(--color-label);
    }
    100% {
        color: var(--color-red);
    }
}

.suggestion img {
    animation: blink-icon 0.5s infinite;
}

@keyframes blink-icon {
    0% {
        filter: var(--filter-red);    
    }
    50% {
        filter: var(--filter-label);    
    }
    100% {
        filter: var(--filter-red);    
    }
}