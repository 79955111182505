.clash-container, .clash-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.clash, .clash-list {
    min-width: 200px;
    width: 70%;
    max-width: 1000px;
    min-height: 400px;
    height: 80vh;
    overflow-y: auto;
}

.clash-header {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    text-align: center;
}

.clash-header h1 {
    margin: 0;
    font-size: var(--font-size-title);
    font-weight: bold;
}

.clash-header p {
    margin: 5px 0;
    color: #666;
}

.clash-players h2 {
    margin-bottom: 10px;
    text-align: center;
}

.clash-players table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.clash-players th,
.clash-players td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
}

.create-button,
.accept-button,
.decline-button,
.continue-button,
.add-button,
.remove-button,
.delete-button {
    border: none;
    font-weight: bolder;
    transition: background-color 0.3s ease-in-out;
    border-radius: var(--border-radius-button);
    color: var(--color-default-button);
}

.accept-button,
.decline-button,
.continue-button,
.create-button, 
.add-button, 
.remove-button,
.delete-button  {
    padding: var(--padding-default-button);
    background: var(--background-default-button);
    margin: 10px;
}

.create-button .icon,
.accept-button .icon,
.add-button .icon {
    color: var(--background-save-button);
}

.decline-button .icon,
.remove-button .icon,
.delete-button .icon {
    color: var(--background-delete-button);
}

.continue-button {
    color: var(--background-default-button);
}

.player-icon-wrapper {
    position: relative;
    display: inline-block;
}

.accepted-badge {
    position: absolute;
    bottom: 10px;
    right: 5px;
    background: none;
    border: none;
    font-size: var(--font-size-title);
    color: var(--background-save-button);
}

.declined-badge {
    position: absolute;
    bottom: 10px;
    right: 0;
    background: none;
    border: none;
    font-size: var(--font-size-title);
    color: var(--background-save-button);
}

.crown-badge {
    position: absolute;
    top: -35px;
    left: 13px;
    background: none;
    border: none;
    font-size: 40px;
    z-index: 1;
}

.create-button:disabled, .add-button:disabled, .remove-button:disabled {
    color: var(--color-default-button-disabled);
    background-color: var(--background-default-button-disabled);
}

.clash h3,
.clash-list h3 {
    font-size: var(--font-size-text);
    font-weight: normal;
    margin: 10px;
    color: var(--color-default);
}

.clash-list .collapsible-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.active-players-container {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.create-clash-container {
    align-items: center;
    margin-top: 20px;
}

.clash-name-input {
    padding: 10px;
    font-size: var(--font-size-text);
    border-radius: var(--border-radius-button);
}

.create-clash-button {
    border: none;
    font-weight: bold;
    font-size: var(--font-size-text);
    border-radius: var(--border-radius-button);
    background-color: var(--background-save-button);
    color: var(--color-save-button);
    padding: var(--padding-default-button);
    transition: background-color 0.3s ease-in-out;
    margin: 0 0 0 20px;
}

.create-clash-button:hover:enabled {
    background-color: var(--background-save-button-hover);
}

.create-clash-button:disabled {
    color: var(--color-save-button-disabled);
    background-color: var(--background-save-button-disabled);
}

.remove-button-single {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    font-size: 14px;
    transition: font-size 0.1s ease-in-out;
}

.remove-button-single:hover:enabled {
    cursor: pointer;
    font-size: 16px;
}

.reaction-list {
    position: fixed;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    z-index: 1;
}

.reaction-item {
    position: absolute;
    bottom: 0;
    font-size: 2rem;
    animation: floatUp 3s ease-out forwards, fadeOut 3s ease-out forwards;
    color: var(--color-default);
}

@keyframes floatUp {
    0% {
        transform: translateY(0);
    }
    80% {
        transform: translateY(-150px);
    }
    100% {
        transform: translateY(-150px);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 480px) {
    .clash, .clash-list {
        width: 100vw;
        margin-top: 40px;
        height: 90vh;
    }

    .clash-players th,
    .clash-players td {
        padding: 8px;
    }

    .create-clash-container {
        padding: 0 20px;
        margin: 0;
    }

    .create-clash-button {
        width: 100%;
        margin: 0;
    }

    .clash-name-input {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        box-sizing: border-box;
    }

}
