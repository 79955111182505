.game-list-container, .game-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.game-list {
    min-width: 200px;
    width: 70%;
    max-width: 1000px;
    min-height: 400px;
    height: 80vh;
    overflow-y: auto;
}

.dices {
    display: inline-flex;
    background: var(--color-background);
    position: relative;
    width: 100%;
    justify-content: center;
}

.restart-prompt {
    margin-top: 10px;
}

.restart-prompt-button {
    padding: 6px 20px;
    border: none;
    border-radius: var(--border-radius-button);
}

.button-yes {
    background-color: var(--background-share-button);
    color: var(--color-share-button);
    margin-right: 10px;
}

.button-no {
    background-color: var(--background-new-game-button);
    color: var(--color-new-game-button);
    margin-left: 10px;
}

.share-button .icon {
    margin: 2px;
}

@media (max-width: 480px) {

    .game-list {
        width: 100vw;
        margin-top: 40px;
        height: 90vh;
    }
    
    .share-button, .new-game-button {
        margin: 10px;
    }

    .game-container {
        height: 90vh;
    }
    
}