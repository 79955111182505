.column {    
	display: inline-grid;
	grid-template-rows: repeat(17, var(--height-box));
}

.column-top-section-sum {
	grid-row-start: 8;
}

.column-middle-section-sum {
	grid-row-start: 11;
}

.column-bottom-section-sum {
	grid-row-start: 17;
}